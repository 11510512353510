import Left from 'assets/image/left.png';
import Right from 'assets/image/right.png';
import { Carousel } from 'primereact/carousel';
import { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars, prettier/prettier
import { responsiveOptions } from 'app/constants/mock-data';
import { generalService } from 'app/services';
import { t } from 'i18next';

export default function CustomerReview() {
    const [customerReview, setCustomerReview] = useState([]);
    const fetchData = async () => {
        const data = await generalService.getListCustomerReview();
        setCustomerReview(data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const customerReviewTemplate = (item) => {
        return (
            <div className='bd-testimonial-4 mx-1 height-customer-say'>
                <div className='bd-testimonial-4__content'>
                    <div className='bd-testimonial-4__quote'>
                        <img src={Right} />
                    </div>
                    <p className='mb-0 fs-lg text-ellipsis-6line'>{item.Content}</p>
                    <div className='d-flex justify-content-center'>
                        <span className='fw-bold text-danger fs-xxl pe-1'>{item.Point}</span>
                        <span className='fs-xxl ps-1'>/10</span>
                    </div>
                    <div className='bd-testimonial-4__quote-2'>
                        <img src={Left} />
                    </div>
                    <div className='d-flex justify-content-center align-items-center pt-2'>
                        <div className='bd-testimonial-4__client text-center'>
                            <h5 className='bd-testimonial-4__client-name'>{item.Name}</h5>
                            <span className='bd-testimonial-4__client-position'>{item.Address}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='auto-container common-back-ground'>
            <div className='text-center sec-title pt-4'>
                <div className='sub-title'>{t('LABEL_CUSTOMER_REVIEW')}</div>
                <h2 className='sec-title'>{t('LABEL_CUSTOMER_SAY')}</h2>
            </div>
            <div className='card news-block'>
                <Carousel
                    value={customerReview}
                    numVisible={2}
                    numScroll={1}
                    responsiveOptions={responsiveOptions}
                    itemTemplate={customerReviewTemplate}
                    circular
                    autoplayInterval={5000}
                />
            </div>
        </div>
    );
}
