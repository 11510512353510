import { ProgressSpinner } from 'primereact/progressspinner';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
const timeout = 500;
export default function Preloader() {
    const nodeRef = useRef(null);
    const preloadText = useSelector((state) => state.common.preloadText);
    const preloader = useSelector((state) => state.common.preloader);
    return (
        <CSSTransition nodeRef={nodeRef} classNames='preloader-fade' in={preloader} timeout={timeout} unmountOnExit>
            <div
                ref={nodeRef}
                className='w-100 h-100 position-absolute bg-white d-flex justify-content-center align-items-center flex-column'
                style={{
                    zIndex: 1080,
                    left: 0,
                    top: 0
                }}>
                <div className='text-left p-5 white-space-pre'>{preloadText}</div>
                <ProgressSpinner strokeWidth='8' animationDuration='.5s' style={{ width: '50px', height: '50px', opacity: '60%' }} />
            </div>
        </CSSTransition>
    );
}
