export const SERVICE = '/services';
export const BLOG = '/blogs';
export const BLOG_DETAIL = '/blog-detail/*';
export const GALLERY = '/gallery';
export const ROOM = '/rooms';
export const ROOM_DETAIL = '/room-detail/*';
export const CONTACT = '/contact';
export const MEETING_EVENT = '/meeting-event';
export const TOUR = '/tours';
export const TOUR_HUE = '/tour-hue';
export const TOUR_NGU_HANH_SON = '/tour-ngu-hanh-son-hoi-an';
export const TOUR_DUA_BAY_MAU_HOI_AN = '/tour-dua-bay-mau-hoi-an';
export const TOUR_DUA_BAY_MAU = '/tour-dua-bay-mau';
export const TOUR_BANAHILL = '/tour-ba-na-hill';
export const TOUR_BANAHILL2 = '/tour-ba-na-hill-vip';
export const CULAOCHAM = '/tour-cu-lao-cham';
export const CHUALINHUNG = '/tour-chua-linh-ung';
export const SWIMMING = '/swimming-pool';
export const SPA = '/spa-message';
export const GYM = '/gym-fitness';
export const ABOUT_US = '/about';
export const ABOUT_CORDIAL = '/cordial-hotel';
export const ABOUT_CORDIAL_GRAND = '/cordial-grand-hotel';
export const BASE_NAME = '/';
export const BAR = '/cabanon-restaurant';
export const SPECIAL_OFFER = '/special-offer';
export const RESTAURANT = '/restaurant';
export const ID = ':id';
export const ROUTER_SLIDER = [
    RESTAURANT,
    BAR,
    CHUALINHUNG,
    CULAOCHAM,
    TOUR_BANAHILL2,
    TOUR_BANAHILL,
    TOUR_DUA_BAY_MAU_HOI_AN,
    TOUR_NGU_HANH_SON,
    TOUR_HUE,
    TOUR_DUA_BAY_MAU
];
export const PERMISSION = {
    1: [],
    2: []
};
