import { ROUTER } from 'app/constants';
import { t } from 'i18next';

export default function NotFound() {
    return (
        <div className='auto-container'>
            <div className='content text-center'>
                <h1>404</h1>
                <h2>{t('LABEL_NOT_FOUND')}</h2>
                <div className='text'>{t('LABEL_NOT_FOUND_DES')}</div>
                <a href={ROUTER.BASE_NAME} className='theme-btn btn-action-sm'>
                    {t('BTN_GO_TO_HOME')}
                </a>
            </div>
        </div>
    );
}
