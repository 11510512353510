import { ROUTER } from 'app/constants';
import { t } from 'app/translations';
import Logo from 'assets/image/logo.png';
import { useEffect, useState } from 'react';
import { Button, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';

export default function Header() {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Navbar expand='lg' data-bs-theme='dark' className={`header ${isScrolled ? 'sticky-top' : 'fixed-top'}`}>
            <div className='w-100 container-fluid'>
                <Navbar.Brand href={ROUTER.BASE_NAME} className='d-flex align-items-center'>
                    <Image src={Logo} className='d-inline-block align-top logo-header' />
                    <span className='px-3 fp-24'>{t('LABEL_HOTEL')}</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse id='basic-navbar-nav' className='justify-content-center align-items-center'>
                    <Nav>
                        <Nav.Link href={ROUTER.BASE_NAME}>{t('LABEL_HOME')}</Nav.Link>
                        <Nav.Link href={ROUTER.ABOUT_US}>{t('LABEL_ABOUT_US')}</Nav.Link>
                        <Nav.Link href={ROUTER.ROOM} className='px-0'>
                            {t('LABEL_ROOM_SUITES')}
                        </Nav.Link>
                        <NavDropdown renderMenuOnMount={true} title={t('LABEL_DINING')} id='menu-dr-service'>
                            <NavDropdown.Item href={ROUTER.RESTAURANT}>{t('LABEL_RESTAURANT')}</NavDropdown.Item>
                            <NavDropdown.Item href={ROUTER.BAR}>{t('LABEL_SKY_BAR')}</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href={ROUTER.MEETING_EVENT}>{t('LABEL_MEETING_EVENT')}</Nav.Link>
                        <Nav.Link href={ROUTER.SERVICE}>{t('LABEL_SERVICE')}</Nav.Link>
                        <Nav.Link href={ROUTER.GALLERY}>{t('LABEL_GALLERY')}</Nav.Link>
                        <NavDropdown renderMenuOnMount={true} title={t('LABEL_BLOG')} id='menu-dr-service'>
                            <NavDropdown.Item href={ROUTER.TOUR}>{t('LABEL_TOUR')} </NavDropdown.Item>
                            <NavDropdown.Item href={ROUTER.BLOG}>{t('LABEL_NEWS')}</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href={ROUTER.CONTACT}>{t('LABEL_CONTACT')}</Nav.Link>
                    </Nav>
                    <Nav.Item className='header-book'>
                        <Button className='btn-style-two btn-lg border-0'>{t('BTN_BOOK_NOW')}</Button>
                    </Nav.Item>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
}
