import { AboutUsHotel } from 'assets/image/home';
import environments from 'environments';
import { ROUTER } from '.';

export const MOCK_TOUR = [
    {
        ID: 1,
        path: ROUTER.TOUR_BANAHILL,
        Name: 'Ba Na Hills - Tour Classic - Ghép đoàn cơ bản',
        Description:
            'Bà Nà Hills được xây dựng trên đỉnh núi Chúa, thuộc huyện Hòa Vang, cách trung tâm thành phố Đà Nẵng khoảng 25 km về phía Tây Nam, ở độ cao 1487 m so với mực nước biển. Đây là khu du lịch trên núi đầu tiên, được ví như “Đà Lạt của miền Trung” hay “ hòn ngọc khí hậu” của Viêt Nam',
        Image: `${environments.PUBLIC_IMAGE}/image/tour/t2_1.jpg`
    },
    {
        ID: 2,
        path: ROUTER.TOUR_BANAHILL2,
        Name: 'Ba Na Hills - GROUP TOUR - VIP',
        Description:
            'Bà Nà Hills được xây dựng trên đỉnh núi Chúa, thuộc huyện Hòa Vang, cách trung tâm thành phố Đà Nẵng khoảng 25 km về phía Tây Nam, ở độ cao 1487 m so với mực nước biển. Đây là khu du lịch trên núi đầu tiên, được ví như “Đà Lạt của miền Trung” hay “ hòn ngọc khí hậu” của Viêt Nam',
        Image: `${environments.PUBLIC_IMAGE}/image/tour/t3_1.jpg`
    },
    {
        ID: 3,
        path: ROUTER.TOUR_DUA_BAY_MAU_HOI_AN,
        Name: 'RỪNG DỪA BẢY MẪU - HỘI AN - BAY MAU COCONUT FOREST - HOI AN ACIENT TOWN',
        Description:
            'Bồng bềnh sông nước khám phá khu sinh thái Rừng dừa Bảy Mẫu và thỏa sức vui đùa với các trò chơi dân gian vui nhộn sau đó lắng mình với không gian cổ kính lãng mạn của phố cổ Hội An.',
        Image: `${environments.PUBLIC_IMAGE}/image/tour/t6_1.jpg`
    },
    {
        ID: 4,
        path: ROUTER.TOUR_DUA_BAY_MAU,
        Name: 'RỪNG DỪA BẢY MẪU -BAY MAU COCONUT FOREST',
        Description:
            'Rừng Dừa Bảy Mẫu hay còn gọi là khu du lịch Rừng Dừa Cẩm Thanh. Có nhiều người còn gọi là Vườn Dừa Bảy Mẫu, đây là một khu du lịch khá nổi tiếng ở thành phố Hội An. Nếu có dịp ghé khu du lịch Rừng Dừa, bạn sẽ được các tay chèo điệu nghệ là những ngư dân chất phác, hiếu khách chở trên những chiếc thuyền thúng nhỏ xinh. ',
        Image: `${environments.PUBLIC_IMAGE}/image/tour/t7_1.jpg`
    },
    {
        ID: 5,
        path: ROUTER.CHUALINHUNG,
        Name: 'LINH ỨNG - NGŨ HÀNH SƠN - HỘI AN',
        Description:
            'Tour Chùa Linh Ứng Ngũ Hành Sơn Hội An 1 Ngày với lịch trình hấp dẫn sẽ đưa quý khách đến với những điểm đến thú vị. Nếu bạn muốn vi vu, khám phá Bán đảo Sơn Trà - Hội An chỉ trong một ngày thì hãy đặt tour ngay nhé!',
        Image: `${environments.PUBLIC_IMAGE}/image/tour/t5_1.jpg`
    },
    {
        ID: 6,
        path: ROUTER.CULAOCHAM,
        Name: 'Cù Lao Chàm - CHAM ISLAND',
        Description:
            'Tour Cù Lao Chàm 1 ngày sẽ đưa bạn đến với những bãi biển trắng mịn, nước biển trong xanh và đại dương với bãi san hô đầy màu sắc. Bạn sẽ được tận hưởng những giây phút thư giãn tại các resort nghỉ dưỡng sang trọng hoặc trên bãi biển hoang sơ, hòa mình vào không gian yên tĩnh và tận hưởng những bữa ăn đậm chất văn hóa địa phương.',
        Image: `${environments.PUBLIC_IMAGE}/image/tour/t4_1.jpg`
    },
    {
        ID: 7,
        path: ROUTER.TOUR_NGU_HANH_SON,
        Name: 'GŨ HÀNH SƠN - HỘI AN - Marble Mountain - Hoi An Acient Town',
        Description:
            'Tour 1/2 ngày tham quan Ngũ Hành Sơn – Đà Nẵng – một danh thắng gồm 5 ngọn núi đá vôi nhô lên trên một bãi cát ven biển với cái tên được người dân đặt theo các yếu tố trong hệ ngũ hành: kim, mộc, thủy, hỏa và thổ. Chinh phục đỉnh Thủy Sơn, đỉnh núi cao nhất chiêm ngưỡng vẻ đẹp thành phố biển Đà Nẵng từ trên cao. Khám phá các hang động tự nhiên đầy huyền bí.',
        Image: `${environments.PUBLIC_IMAGE}/image/tour/t8_1.jpg`
    },
    {
        ID: 8,
        path: ROUTER.TOUR_HUE,
        Name: 'HUẾ - HUE 1 DAY',
        Description:
            'Tour tham quan Huế, đem lại nhiều trải nghiệm thú vị. Tìm hiểu về lịch sử dưới 13 triều đại phong kiến tại Đại Nội Huế. các lăng mộ của Minh Mạng – Khải Định . Các làng nghề truyền thống. như làng nón. làng hương và thưởng thức ẩm thực. mang đậm phong cách cung đình Huế.',
        Image: `${environments.PUBLIC_IMAGE}/image/tour/t1_1.jpg`
    }
];
export const aboutData = [
    {
        itemImageSrc: AboutUsHotel,
        thumbnailImageSrc: AboutUsHotel,
        alt: 'Cordial Hotel',
        title: 'About Us'
    }
];
export const responsiveOptions = [
    {
        breakpoint: '1400px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '1199px',
        numVisible: 3,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
    }
];
export const InfoHotel = {
    address1: '27-29 Loseby, Sơn Trà, Đà Nẵng',
    address2: '30 Loseby, Sơn Trà, Đà Nẵng',
    phone: '(+84) 2363996837 - (+84) 2363996839',
    email: 'info@cordialhotel.com.vn'
};
