import { ROUTER } from 'app/constants';
import { commonService, generalService } from 'app/services';
import { useRedirect } from 'app/utils/browser';
import environments from 'environments';
import { t } from 'i18next';
import moment from 'moment';
import { Image } from 'primereact/image';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
export default function RightBlog() {
    const redirect = useRedirect();
    const [recent, setRecent] = useState({ recent: [], categories: [], tags: [] });
    const recentBlog = async () => {
        const data = await generalService.recentBlog();
        setRecent(data);
    };
    const renderCategory = () => {
        const list = [];
        for (const item of recent.categories) {
            list.push(
                <li className='category' key={`category_${item.ID}`}>
                    <a href='#'>{item.Name}</a>
                </li>
            );
        }
        return list;
    };
    const renderTag = () => {
        const list = [];
        for (const item of recent.tags) {
            list.push(
                <Button className='btn-tag mx-1' variant='default' key={`tag${item.ID}`}>
                    {item.Name}
                </Button>
            );
        }
        return list;
    };
    const renderRecent = () => {
        const list = [];
        for (const item of recent.recent) {
            list.push(
                <li key={`blogs_${item.ID}`}>
                    <a
                        href='/blog-detail'
                        className='pt-2 d-flex'
                        onClick={(e) => {
                            e.preventDefault();
                            redirect(generatePath(ROUTER.BLOG_DETAIL), ROUTER.ID, { id: item.ID }, item.ID);
                        }}>
                        <Image src={`${environments.PUBLIC_IMAGE}/image/blogs/${item.ID}/${item.Images}`} alt='' preview />
                        <p className='text-ellipsis-2line pt-1 mb-0'>{item.Title}</p>
                    </a>
                    <div className='text-ellipsis-2line'>{moment(item.CreatedAt).format('YYYY-MM-DD')}</div>
                </li>
            );
        }
        return list;
    };
    useEffect(() => {
        commonService.preloader.hide();
        recentBlog();
    }, []);
    return (
        <div className='col-md-4 col-sm-12'>
            <div className='widget'>
                <div className='recent-post'>
                    <h5 className='widget-title mb-3'>{t('LABEL_RECENT_POST')}</h5>
                    <ul className='d-grid'>{renderRecent()}</ul>
                </div>
            </div>
            <div className='widget'>
                <h5 className='widget-title mb-3'>{t('LABEL_CATEGORY')}</h5>
                <ul>{renderCategory()}</ul>
            </div>
            <div className='widget'>
                <h5 className='widget-title mb-3'>{t('LABEL_TAGS')}</h5>
                <div className='btn-list'>{renderTag()}</div>
            </div>
        </div>
    );
}
