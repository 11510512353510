import { t } from 'i18next';

export default function Map() {
    return (
        <div className="row mx-0'">
            <div className='col-md-9 col-sm-12 px-0'>
                <iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.8410749030654!2d108.23597414781396!3d16.07373474503646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3142176c1d4bf767%3A0x502fd69b437fbf81!2sCordial%20Grand%20Hotel!5e0!3m2!1sfr!2sus!4v1711035653000!5m2!1sfr!2sus'
                    width='100%'
                    height='450'
                    allowFullScreen=''
                    loading='lazy'
                    referrerPolicy='no-referrer-when-downgrade'></iframe>
            </div>
            <div className='col-md-3 col-sm-12 px-4'>
                <p className='fw-bold text-center mb-2'>{t('LABEL_MAP_LOCATION')}</p>
                <p className='fw-bold txt-main text-center mb-1'>{t('LABEL_MAP_SPA')}</p>
                <div className='align-items-center d-flex py-2 w-100 fw-bold txt-main'>4 {t('LABEL_MIN')}</div>
                <div className='align-items-center d-flex py-2 w-100'>{t('LABEL_MAP_MY_KHE')}</div>
                <div className='align-items-center d-flex py-2 w-100 ps-1 fw-bold txt-main'>12 {t('LABEL_MIN')}</div>
                <div className='align-items-center d-flex py-2 w-100'>{t('LABEL_MAP_DA_NANG')}</div>
                <div className='align-items-center d-flex py-2 w-100 fw-bold txt-main'>6 {t('LABEL_MIN')}</div>
                <div className='align-items-center d-flex py-2 w-100'>{t('LABEL_MAP_CAU_RONG')}</div>
                <div className='align-items-center d-flex py-2 w-100 ps-1 fw-bold txt-main'>7 {t('LABEL_MIN')}</div>
                <div className='align-items-center d-flex py-2 w-100'>{t('LABEL_MAP_LOVE')}</div>
                <div className='align-items-center d-flex py-2 w-100 ps-1 fw-bold txt-main'>17 {t('LABEL_MIN')}</div>
                <div className='align-items-center d-flex py-2 w-100'>{t('LABEL_MAP_PAGODA')}</div>
            </div>
        </div>
    );
}
