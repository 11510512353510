import { t } from 'i18next';
import Map from '../common/map';
export default function LocationElement() {
    return (
        <div className='auto-container common-back-ground'>
            <div className='text-center sec-title pt-4'>
                <div className='sub-title'>{t('LABEL_LOCATION_BEUTIFUL')}</div>
                <h2 className='sec-title'>{t('LABEL_DISCOVER_AFF_TOUR')}</h2>
            </div>
            <div className='pb-3'>
                <Map />
            </div>
        </div>
    );
}
