/* eslint-disable no-undef */
import store from 'app/store';
import { preloaderAction, preloadText, setConfirming, setEditConfirmMSg, setEditing } from 'app/store/common';
import { t } from 'app/translations';

export default {
    preloader: {
        show: () => store.dispatch(preloaderAction(true)),
        hide: () => store.dispatch(preloaderAction(false)),
        text: (data) => store.dispatch(preloadText(data))
    },
    /**
     * @returns {import("primereact/toast").Toast}
     */
    get toast() {
        return global.toast;
    },
    /**
     * @returns {import("app/views/core/modal").CustomModal}
     */
    get modal() {
        return global.modal;
    },
    /**
     * @returns {import("app/views/core/confirm-replace").ConfirmReplace}
     */
    get confirmReplace() {
        return global.confirmReplace;
    },
    confirmSave: {
        editConfirmMsg: (msg) => store.dispatch(setEditConfirmMSg(msg)),
        editing: () => store.dispatch(setEditing(true)),
        release: () => store.dispatch(setEditing(false)),
        trigger: async () => {
            const { editing, editingMsg } = store.getState().common;
            if (editing) {
                store.dispatch(setConfirming(true));
                const result = await global.modal.showConfirm({
                    header: t('LABEL_CONFIRM'),
                    message: t(editingMsg),
                    img: 'warning-message',
                    customClass: 'chip-configuration-warning'
                });
                store.dispatch(setConfirming(false));
                if (result) {
                    store.dispatch(setEditing(false));
                }
                return result;
            }
            return true;
        }
    },
    /**
     * @returns {import("primereact/messages").Messages}
     */
    get messages() {
        return global.messages;
    }
};
