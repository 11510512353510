import { NotFound } from 'app/views/pages';
import { HttpStatusCode } from 'axios';
import _ from 'lodash';
import { useRouteError } from 'react-router-dom';

export default function LoaderDataError() {
    const error = useRouteError();
    const status = _.result(error, 'response.status');
    if (status === HttpStatusCode.NotFound) {
        return <NotFound />;
    }
    return null;
}
