import { DINNING_TYPE, GROUP } from 'app/constants';
import { responsiveOptions } from 'app/constants/mock-data';
import { commonService, serviceService } from 'app/services';
import environments from 'environments';
import { t } from 'i18next';
import { Carousel } from 'primereact/carousel';
import { Image } from 'primereact/image';
import { useEffect, useState } from 'react';
export default function Restaurant() {
    const [data, setData] = useState({});
    /**
     * fetch data from db with ID
     * @returns
     */
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await serviceService.get({ type: GROUP.DINNING, category: DINNING_TYPE.RESTAURANT });
        setData(data);
        commonService.preloader.hide();
        return true;
    };
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);
    const itemTemplate = (item) => {
        return (
            <div className='p-3 flex justify-center'>
                <Image
                    src={`${environments.PUBLIC_IMAGE}/image/dinning/${DINNING_TYPE.RESTAURANT}/${item}`}
                    className='w-1/2 shadow-sm h-100'
                    preview
                />
            </div>
        );
    };
    return (
        <div className='services-content'>
            <div className='py-5 row'>
                <div className='col-12'>
                    <h2 className='fw-bold text-center py-3'>{t('LABEL_RESTAURANT')}</h2>
                    <div className='my-3'>
                        <div className='single-blog-details-inner'>
                            <div className='py-4'>
                                <div className='single-blog-content px-4'>
                                    <p>{data?.Content}</p>
                                    <p>
                                        {t('LABEL_LOCATION')}: {data?.Location}
                                    </p>
                                    <p>
                                        {t('LABEL_OPEN_HOUR')}: {data?.Operating}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <Carousel
                                    value={data?.ImageList || []}
                                    numVisible={3}
                                    numScroll={3}
                                    responsiveOptions={responsiveOptions}
                                    itemTemplate={itemTemplate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
