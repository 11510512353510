import { GROUP, ROUTER, SERVICE_TYPE } from 'app/constants';
import { commonService, serviceService } from 'app/services';
import UtilityElement from 'app/views/pages/home/utility';
import environments from 'environments';
import { Image } from 'primereact/image';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
export default function Services() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    /**
     * fetch data from db with ID
     * @returns
     */
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await serviceService.search({ group: GROUP.SERVICE });
        setData(data);
        commonService.preloader.hide();
        return true;
    };
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);
    return (
        <div>
            <div className='auto-container home-auto-container'>
                <div className='title py-5'></div>
                <div className='services-block-wrapper'>
                    <div className='service-block-three'>
                        <div className='row'>
                            <div className='col-lg-7 image-column'>
                                <div className='image'>
                                    <Image
                                        src={`${environments.PUBLIC_IMAGE}/image/service/${SERVICE_TYPE.GYM}/${data[0]?.ImageList[0]}`}
                                        className='w-1/2 shadow-sm'
                                        preview
                                    />
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='content sec-title'>
                                    <div className='sub-title'>{t('LABEL_SERVICE')}</div>
                                    <h2 className='sec-title small mb-30'>{t('LABEL_GYM_FITNESS')}</h2>
                                    <div className='text-two'>
                                        <p className='py-1 mb-0 pt-5'>{data[0]?.Content}</p>
                                    </div>
                                    <div className='time pt-2'>
                                        {t('LABEL_LOCATION')}: {data[0]?.Location}
                                    </div>
                                    <div className='time'>
                                        {t('LABEL_OPEN_HOUR')}: {data[0]?.Operating}
                                    </div>
                                    <div className='link-btn'>
                                        <Button className='btn-action' variant='default' onClick={() => navigate(ROUTER.GYM)}>
                                            {t('BTN_MORE_DETAIL')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='service-block-three'>
                        <div className='row left-content'>
                            <div className='col-lg-7 image-column'>
                                <div className='image'>
                                    <Image
                                        src={`${environments.PUBLIC_IMAGE}/image/service/${SERVICE_TYPE.SWIMMING}/${data[1]?.ImageList[0]}`}
                                        className='w-1/2 shadow-sm'
                                        preview
                                    />
                                </div>
                            </div>
                            <div className='col-lg-5 content-column'>
                                <div className='inner-column content'>
                                    <div className='sec-title'>
                                        <div className='sub-title'>{t('LABEL_SERVICE')}</div>
                                    </div>
                                    <h2 className='sec-title small mb-30'>{t('LABEL_SWIMMING_POOL')}</h2>
                                    <div className='text-two text-ellipsis-5line pe-3'>{data[1]?.Content}</div>
                                    <div className='time pt-2'>
                                        {t('LABEL_LOCATION')}: {data[1]?.Location}
                                    </div>
                                    <div className='time'>
                                        {t('LABEL_OPEN_HOUR')}: {data[1]?.Operating}
                                    </div>
                                    <div className='link-btn'>
                                        <Button className='btn-action' variant='default' onClick={() => navigate(ROUTER.SWIMMING)}>
                                            {t('BTN_MORE_DETAIL')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='service-block-three'>
                        <div className='row'>
                            <div className='col-lg-7 image-column'>
                                <div className='image'>
                                    <Image
                                        src={`${environments.PUBLIC_IMAGE}/image/service/${SERVICE_TYPE.SPA}/${data[2]?.ImageList[0]}`}
                                        className='w-1/2 shadow-sm'
                                        preview
                                    />
                                </div>
                            </div>
                            <div className='col-lg-5 content-column'>
                                <div className='content'>
                                    <div className='sub-title'>{t('LABEL_SERVICE')}</div>
                                    <h2 className='sec-title small mb-30'>{t('LABEL_SPA_MESSAGE')}</h2>
                                    <div className='text-two text-ellipsis-5line'>{data[2]?.Content}</div>
                                    <div className='time pt-2'>
                                        {t('LABEL_LOCATION')}: {data[2]?.Location}
                                    </div>
                                    <div className='time'>
                                        {t('LABEL_OPEN_HOUR')}: {data[2]?.Operating}
                                    </div>
                                    <div className='link-btn'>
                                        <Button className='btn-action' variant='default' onClick={() => navigate(ROUTER.SPA)}>
                                            {t('BTN_MORE_DETAIL')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {UtilityElement()}
                </div>
            </div>
        </div>
    );
}
