import _ from 'lodash';
import { Image } from 'primereact/image';
import { Carousel } from 'react-bootstrap';
// eslint-disable-next-line react/prop-types
export default function Slideshow({ isMainPage, images }) {
    const renderTemplate = () => {
        const list = [];
        if (!_.isArray(images)) {
            return list;
        }
        for (const item of images) {
            console.log('item', `url(${item})`);
            const value = (
                <Carousel.Item className='h-100-vh w-100'>
                    {/* <div
                        style={{
                            backgroundImage: `url(${item})`,
                            backgroundRepeat: 'no-repeat'
                        }}
                        className='d-inline-block align-top w-100 h-100-vh'
                    /> */}
                    <Image src={item} className='d-inline-block align-top' />
                </Carousel.Item>
            );
            list.push(value);
        }
        return list;
    };
    return isMainPage ? (
        <Carousel data-bs-theme='light'>{renderTemplate()}</Carousel>
    ) : (
        // <div
        //     style={{
        //         backgroundImage: `url(${images})`
        //     }}
        //     className='bgSlide d-inline-block align-top'
        // />
        <Image src={images} className='bgSlide d-inline-block align-top' />
    );
}
