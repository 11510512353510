import { ROUTER } from 'app/constants';
import { commonService, generalService } from 'app/services';
import { useRedirect } from 'app/utils/browser';
import environments from 'environments';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import RightBlog from './right-blog';
export default function Blogs() {
    const { t } = useTranslation();
    const disabledDay = moment({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const [date, setDate] = useState('');
    const [data, setData] = useState([]);
    const [searchField, setSearchField] = useState('');
    const redirect = useRedirect();
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await generalService.getListBlog({ searchField: '' });
        setData(data);
        commonService.preloader.hide();
        return true;
    };
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);
    const news = () => {
        const list = [];
        for (const item of data) {
            const value = (
                <div className='col-md-6 col-sm-12 px-1 py-3 image-tour-ch' key={item.ID}>
                    <div className='mx-2'>
                        <div>
                            <Image src={`${environments.PUBLIC_IMAGE}/image/blogs/${item.ID}/${item.Images}`} alt='' preview />
                        </div>
                        <div className='events-body px-3'>
                            <div className='entity-header'>
                                <div className='pt-3'>
                                    <time dateTime='2019-03-02T05:00:00Z'>{moment(item.CreatedAt).format('YYYY-MM-DD HH:mm:ss')}</time>
                                </div>
                                <h2 className='event-title'>
                                    <a
                                        href='/#'
                                        className='fp-24 text-ellipsis-1line'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            redirect(generatePath(ROUTER.BLOG_DETAIL), ROUTER.ID, { id: item.ID }, item.ID);
                                        }}>
                                        {item.Title}
                                    </a>
                                </h2>
                            </div>
                            <div className='pt-2'>
                                <p className='entity-body'>{item.SpecialContent}</p>
                            </div>
                            <div className='row mx-0, px-3 pb-5'>
                                <Button
                                    className='btn-action-sm'
                                    variant='default'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirect(generatePath(ROUTER.BLOG_DETAIL), ROUTER.ID, { id: item.ID }, item.ID);
                                    }}>
                                    {t('BTN_MORE_DETAIL')}
                                </Button>
                                <Button variant='social'>
                                    <span className='pi pi-instagram' />
                                </Button>
                                <Button variant='social'>
                                    <span className='pi pi-facebook' />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
            list.push(value);
        }
        return list;
    };
    return (
        <div className='auto-container events py-5'>
            <div className='row'>
                <div className='col-md-8 col-sm-12 row d-block'>
                    <div className='row mx-0 pt-4'>
                        <div className='col-md-5 col-sm-12 px-0 py-2'>
                            <Calendar
                                value={date}
                                onChange={(e) => {
                                    setDate(e.target.value);
                                }}
                                baseZIndex={1055}
                                maxDate={disabledDay.toDate()}
                                inputClassName='p-calendar-input fs-md'
                                placeholder={t('PLACEHOLDER_SELECT_DATE')}
                                showButtonBar
                                showIcon
                                selectionMode='range'
                                className='w-100 fs-md'
                                icon='icon calendar'
                                readOnlyInput={true}
                                clearButtonClassName='border btn-cancel-sm rounded calendar-btn fs-md h-100'
                                todayButtonClassName='border btn-action-sm rounded calendar-btn fs-md text-white border-0 px-4'
                            />
                        </div>
                        <div className='col-md-5 col-sm-12 search px-0 py-2'>
                            <div className='search position-relative px-2'>
                                <form action='' method=''>
                                    <InputText
                                        type='text'
                                        className='w-100'
                                        value={searchField}
                                        placeholder={t('PLACEHOLDER_SEARCH_HERE')}
                                        onChange={(e) => setSearchField(e.target.value)}
                                    />
                                    <Button type='submit' className='icons'>
                                        <i className='pi pi-search'></i>
                                    </Button>
                                </form>
                            </div>
                        </div>
                        <div className='col-md-2 col-sm-12 px-0 py-2'>
                            <Button className='w-100 h-60px btn-action border-0 mx-2'>{t('BTN_FIND_NEW')}</Button>
                        </div>
                    </div>
                    <div className='row mx-0'>{news()}</div>
                </div>
                <RightBlog />
            </div>
        </div>
    );
}
