import { MOCK_TOUR } from 'app/constants';
import { Image } from 'primereact/image';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
export default function Tours() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tour = () => {
        const list = [];
        for (const item of MOCK_TOUR) {
            const value = (
                <div className='col-md-4 col-sm-12 px-1 py-3' key={item.ID}>
                    <div className='mx-2'>
                        <div className='image-tour-ch'>
                            <Image src={item.Image} alt='' preview />
                        </div>
                        <div className='events-body height-290 px-3'>
                            <div className='entity-header'>
                                <h2 className='event-title pt-3'>
                                    <a href={item.path}>{item.Name}</a>
                                </h2>
                            </div>
                            <div className='pt-2'>
                                <p className='entity-body'>{item.Description}</p>
                            </div>
                            <div className='row mx-0, px-3 pb-3'>
                                <Button className='btn-action-sm' variant='default' onClick={() => navigate(item.path)}>
                                    {t('BTN_MORE_DETAIL')}
                                </Button>
                                <Button variant='social'>
                                    <span className='pi pi-instagram' />
                                </Button>
                                <Button variant='social'>
                                    <span className='pi pi-facebook' />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
            list.push(value);
        }
        return list;
    };
    return (
        <div className='auto-container events py-5'>
            <div className='row'>
                <div className='col-md-12 row'>{tour()}</div>
            </div>
        </div>
    );
}
