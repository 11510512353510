import { Carousel } from 'primereact/carousel';
import { Button } from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars, prettier/prettier
import { MOCK_TOUR } from 'app/constants';
import { responsiveOptions } from 'app/constants/mock-data';
import { Image } from 'primereact/image';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function CheapTours() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const productTemplate = (item) => {
        return (
            <div className='px-1 py-1 events'>
                <div className='mx-2'>
                    <div className='image-tour-ch'>
                        <Image src={item.Image} alt='' preview />
                    </div>
                    <div className='events-body height-200 px-3'>
                        <div className='entity-header'>
                            <h2 className='event-title pt-3'>
                                <a href={item.path}>{item.Name}</a>
                            </h2>
                        </div>
                        <div className='pt-2'>
                            <p className='entity-body'>{item.Description}</p>
                        </div>
                        <div className='row mx-0, px-3 pb-5'>
                            <Button className='btn-action-sm' variant='default' onClick={() => navigate(item.path)}>
                                {t('BTN_MORE_DETAIL')}
                            </Button>
                            <Button variant='social-1'>
                                <span className='pi pi-instagram' />
                            </Button>
                            <Button variant='social-1'>
                                <span className='pi pi-facebook' />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='auto-container common-back-ground'>
            <div className='text-center sec-title pt-4'>
                <div className='sub-title'>{t('LABEL_CHEAP_TOUR')}</div>
                <h2 className='sec-title'>{t('LABEL_DISCOVER_AFF_TOUR')}</h2>
            </div>
            <div className='card news-block'>
                <Carousel
                    value={MOCK_TOUR}
                    numVisible={3}
                    numScroll={1}
                    responsiveOptions={responsiveOptions}
                    itemTemplate={productTemplate}
                    circular
                    autoplayInterval={5000}
                />
            </div>
        </div>
    );
}
