import { BLOG_DETAIL_URL, BLOG_RECENT_URL, BLOG_URL, CONTACT_URL, CUSTOMER_REVIEW_URL } from 'app/constants';
import { requestGet, requestPost } from 'app/utils/request';
import { HttpStatusCode } from 'axios';
const getListCustomerReview = async () => {
    const params = {
        first: 0,
        searchField: '',
        rows: 100,
        sortField: 'UpdatedAt',
        sortOrder: 'DESC'
    };
    const res = await requestGet(CUSTOMER_REVIEW_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload.data;
    } else {
        return [];
    }
};
const createContact = (data) => requestPost(CONTACT_URL, data);
const getListBlog = async () => {
    const params = {
        searchField: ''
    };
    const res = await requestGet(BLOG_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload.data;
    } else {
        return [];
    }
};
const detailBlog = async (params) => {
    const res = await requestGet(BLOG_DETAIL_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
const recentBlog = async () => {
    const res = await requestGet(BLOG_RECENT_URL); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: {
                recent: [],
                categories: [],
                tags: []
            }
        };
    }
};
export default { getListCustomerReview, createContact, getListBlog, detailBlog, recentBlog };
