import { aboutData } from 'app/constants';
import { Galleria } from 'primereact/galleria';
import { useEffect, useState } from 'react';
import CordialGrandHotel from './cordial-grand-hotel';
import CordialHotel from './cordial-hotel';
export default function About() {
    const [images, setImages] = useState(null);
    // const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        setImages(aboutData);
    }, []);
    const itemTemplate = (item) => {
        return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    };

    return (
        <div>
            {/* about us */}
            <div className='row about-us-row g-0 wow' data-wow-delay='100ms'>
                <div className='image-column col-lg-6'>
                    <div className='card'>
                        <Galleria
                            value={images}
                            showThumbnails={false}
                            showItemNavigators
                            showItemNavigatorsOnHover
                            item={itemTemplate}
                            circular
                            autoPlay
                            transitionInterval={4000}
                        />
                    </div>
                </div>
                <div className='content-column col-lg-6'>
                    <div className='inner-column'>
                        <div className='content-box'>
                            <div className='sec-title'>
                                <span className='sub-title'>About The Hotel</span>
                                {/* <h2 className='small mb-20'>Cordial Hotel</h2> */}
                                <div className='text'>
                                    Cordial Hotel is a 4-star hotel located in Da Nang, Vietnam. The hotel offers various amenities such as
                                    free bikes, free private parking, an outdoor swimming pool, and a fitness center. Additionally, it
                                    features services like room service, an ATM, a 24-hour front desk, airport shuttle service, shared
                                    lounge, and free Wi-Fi. Guests can enjoy various breakfast options including buffet, à la carte, or
                                    continental, and the on-site restaurants serve American, Vietnamese, and local cuisine. Additionally,
                                    customers can request dairy-free and vegan dishes.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* RoomSuite Cordial Hotel */}
            <div className='row room-suites g-0 wow' data-wow-delay='100ms'>
                <CordialHotel />
            </div>
            {/* RoomSuite Cordial Grand Hotel */}
            <div className='row room-suites left-content g-0 wow' data-wow-delay='100ms'>
                <CordialGrandHotel />
            </div>
        </div>
    );
}
