import environments from 'environments';
import { t } from 'i18next';
import { Image } from 'primereact/image';
import { Row } from 'react-bootstrap';
export default function AboutGallery() {
    return (
        <div className='row d-flex w-100 gallery-about'>
            <div className='row text-center pt-5'>
                <h2 className='pb-3'>{t('LABEL_GALLERY')}</h2>
            </div>
            <div className='col-md-12 col-sm-12 gallery d-flex w-100'>
                <Row className='m-0 gallery-list switch-right col-12'>
                    <div className='col-md-6 col-sm-12 px-0'>
                        <div className='img-right'>
                            <Image src={`${environments.PUBLIC_IMAGE}/image/gallery/6/1.jpg`} alt='Image' preview />
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 px-0 h-100'>
                        <div className='img-left'>
                            <Row className='m-0 gallery-height'>
                                <div className='col-md-6 col-sm-12 px-0 h-100'>
                                    <div className='img-gs'>
                                        <Image src={`${environments.PUBLIC_IMAGE}/image/gallery/6/2.jpg`} alt='Image' preview />
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-12 px-0 h-100'>
                                    <div className='img-ge'>
                                        <Image src={`${environments.PUBLIC_IMAGE}/image/gallery/6/3.jpg`} alt='Image' preview />
                                    </div>
                                </div>
                            </Row>
                            <Row className='m-0 gallery-height'>
                                <div className='col-md-12 col-sm-12 px-0 h-100'>
                                    <div className='img-bottom'>
                                        <Image src={`${environments.PUBLIC_IMAGE}/image/gallery/6/4.jpg`} alt='Image' preview />
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </Row>
            </div>
            <div className='col-md-12 col-sm-12 gallery d-flex w-100'>
                <Row className='m-0 gallery-list mt-2 switch-left col-12'>
                    <div className='col-md-6 col-sm-12 px-0 img-big'>
                        <div className='img-left'>
                            <Image src={`${environments.PUBLIC_IMAGE}/image/gallery/6/5.jpg`} alt='Image' preview />
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 px-0 h-100'>
                        <div className='img-group'>
                            <Row className='m-0 gallery-height'>
                                <div className='col-md-6 col-sm-12 px-0 h-100'>
                                    <div className='img-right'>
                                        <Image src={`${environments.PUBLIC_IMAGE}/image/gallery/6/6.jpg`} alt='Image' preview />
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-12 px-0 h-100'>
                                    <div className='image-about h-100'>
                                        <Image src={`${environments.PUBLIC_IMAGE}/image/gallery/6/7.jpg`} alt='Image' preview />
                                    </div>
                                </div>
                            </Row>
                            <Row className='m-0 gallery-height'>
                                <div className='col-md-12 col-sm-12 px-0 h-100'>
                                    <div className='img-bottom'>
                                        <Image src={`${environments.PUBLIC_IMAGE}/image/gallery/6/8.jpg`} alt='Image' preview />
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </Row>
            </div>
        </div>
    );
}
