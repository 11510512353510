import { ENUM_TOUR } from 'app/constants';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { parseData, renderContent, renderLeftPage } from './common';
export default function BanaHillTour() {
    const [data, setData] = useState([]);
    useEffect(() => {
        const res = parseData(ENUM_TOUR.BANA_HILL);
        setData(res);
    }, []);
    return (
        <div className='container'>
            <div className='d-flex justify-content-center py-5 row'>
                <div className='col-12'>
                    <div className='my-3'>
                        <div className='row pt-3'>
                            {renderLeftPage({ Type: ENUM_TOUR.BANA_HILL, service: data.service })}
                            <div className='w-70'>
                                <div className='font-ui-mono text-end row mb-0'>
                                    <p className='color-rebeccapurple fw-bold fs-60 w-50 mb-0'>{t('TOUR_BANA_HILL')}</p>
                                    <p className='color-blue fw-bold fs-24 ps-3 text-right w-50 mb-0 align-content-end'>
                                        {t('TOUR_BANA_HILL_NOTE')}
                                    </p>
                                </div>
                                <p className='font-ui-mono color-chocolate fw-bold fs-24 text-right'>{t('LABEL_TOUR_GROUP')}</p>
                                <p className='mb-0 pb-3 font-ui-mono fs-md fw-bold'>{t('TOUR_BANA_HILL_DES')}</p>
                                {renderContent(data.tour)}
                                <hr className='break-line-bd' />
                                <div className='row pb-3'>
                                    <div className='w-50 px-3 pb-3'>
                                        <p className='font-ui-mono color-rebeccapurple mb-0 fw-bold pt-1'>{t('TOUR_NOTE')}</p>
                                        <ul className='fs-md font-ui-mono mg-bt-12'>
                                            <li>{t('TOUR_NOTE_1')}</li>
                                            <li>{t('TOUR_NOTE_2')}</li>
                                        </ul>
                                        <p className='font-ui-mono mb-0 fs-md'>{t('TOUR_NOTE_3')}</p>
                                        <p className='fst-italic font-ui-mono mb-0 fs-md'>{t('TOUR_NOTE_4')}</p>
                                    </div>
                                    <div className='w-50 px-3 pb-3'>
                                        <p className='font-ui-mono color-rebeccapurple mb-0 fw-bold pt-1'>{t('TOUR_NOT_INCLUDE')}</p>
                                        <ul className='fs-md font-ui-mono mg-bt-12'>
                                            <li>{t('TOUR_NOT_INCLUDE_2')}</li>
                                            <li>{t('TOUR_NOT_INCLUDE_3')}</li>
                                            <li>{t('TOUR_NOT_INCLUDE_1')}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='py-2 bg-chocolate'>
                                    <p className='mb-0 px-3 text-white text-center'>{t('LABEL_TOUR_FOOTER')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
