import { IMAGE_TYPE, ROUTER } from 'app/constants';
import { ROUTER_SLIDER } from 'app/constants/routes';
import { commonService, galleryServiceService } from 'app/services';
import { Footer, Header } from 'app/views/core';
import environments from 'environments';
import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Slideshow from './header/slide-show';

export default function MasterLayout() {
    const [image, setImage] = useState();
    const [isMainPage, setIsMainPage] = useState(false);
    const getListImage = async () => {
        const flag = !ROUTER_SLIDER.includes(window.location.pathname);
        const data = await galleryServiceService.getCarousel();
        let res = [];
        if (Array.isArray(data) && data.length !== 0) {
            res = data.filter((v) => v.Type === IMAGE_TYPE.CAROUSEL);
        }
        switch (window.location.pathname) {
            case ROUTER.RESTAURANT:
                setImage(`${environments.PUBLIC_IMAGE}/image/home/header/${data.filter((v) => v.Type === IMAGE_TYPE.RESTAURANT)[0].Image}`);
                break;
            case ROUTER.BAR:
                setImage(
                    `${environments.PUBLIC_IMAGE}/image/home/header/${data.filter((v) => v.Type === IMAGE_TYPE.CABANON_RESTAURANT)[0].Image}`
                );
                break;
            case ROUTER.TOUR_BANAHILL:
                setImage(`${environments.PUBLIC_IMAGE}/image/tour/t2_1.jpg`);
                break;
            case ROUTER.TOUR_BANAHILL2:
                setImage(`${environments.PUBLIC_IMAGE}/image/tour/t3_1.jpg`);
                break;
            case ROUTER.TOUR_DUA_BAY_MAU_HOI_AN:
                setImage(`${environments.PUBLIC_IMAGE}/image/tour/t6_1.jpg`);
                break;
            case ROUTER.TOUR_DUA_BAY_MAU:
                setImage(`${environments.PUBLIC_IMAGE}/image/tour/t7_1.jpg`);
                break;
            case ROUTER.CHUALINHUNG:
                setImage(`${environments.PUBLIC_IMAGE}/image/tour/t5_1.jpg`);
                break;
            case ROUTER.CULAOCHAM:
                setImage(`${environments.PUBLIC_IMAGE}/image/tour/t4-1.jpg`);
                break;

            case ROUTER.TOUR_NGU_HANH_SON:
                setImage(`${environments.PUBLIC_IMAGE}/image/tour/t8_1.jpg`);
                break;
            case ROUTER.TOUR_HUE:
                setImage(`${environments.PUBLIC_IMAGE}/image/tour/t1_1.jpg`);
                break;
            default:
                res = res.map((v) => `${environments.PUBLIC_IMAGE}/image/home/carousel/${v.Image}`);
                setImage(res);
                break;
        }
        setIsMainPage(flag);
    };
    useEffect(() => {
        commonService.preloader.hide();
        getListImage();
    }, []);
    return (
        <Col className='page'>
            <Header />
            <Slideshow isMainPage={isMainPage} images={image} />
            <Outlet />
            <Footer />
        </Col>
    );
}
