import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    preloader: true,
    preloadText: '',
    editing: false,
    editingMsg: 'M015',
    confirming: false
};

export const common = createSlice({
    name: 'common',
    initialState,
    reducers: {
        preloaderAction: (state, action) => {
            state.preloader = action.payload;
            if (!action.payload) {
                state.preloadText = '';
            }
        },
        preloadText: (state, action) => {
            state.preloadText = action.payload;
        },
        setEditing: (state, action) => {
            state.editing = action.payload;
            if (!action.payload) {
                state.editingMsg = 'M015';
            }
        },
        setConfirming: (state, action) => {
            state.confirming = action.payload;
        },
        setEditConfirmMSg: (state, action) => {
            state.editingMsg = action.payload;
        }
    }
});
export const { preloaderAction, preloadText, setEditing, setEditConfirmMSg, setConfirming } = common.actions;
export default common.reducer;
