import { ENUM_TOUR } from 'app/constants';
import environments from 'environments';
import { t } from 'i18next';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
const renderImage = (type) => {
    const list = [];
    for (let i = 1; i < 4; i++) {
        list.push(
            <div className='img-left pt-3' key={i}>
                <Image src={`${environments.PUBLIC_IMAGE}/image/tour/t${type}_${i}.jpg`} preview className='h-100 w-100' />
            </div>
        );
    }
    return list;
};
const renderLeftMenu = (data) => {
    const list = [];
    for (let i = 1; i <= data?.index; i++) {
        list.push(<li key={i}>{t(`${data?.key}${i}`)}</li>);
    }
    return <ul className='fs-md font-ui-mono'>{list}</ul>;
};
export const renderContent = (data) => {
    const list = [];
    for (let i = 1; i <= data?.index; i++) {
        list.push(
            <p className='mb-0 fs-md font-ui-mono'>
                <span className='fw-bold'>{t(`${data.time}${i}`)}</span>
                {t(`${data.key}${i}`)}
            </p>
        );
    }
    return <div>{list}</div>;
};

export const renderMenu = (data) => {
    const list = [];
    for (let i = 1; i <= data?.index; i++) {
        list.push(<p className='mb-0 fs-md font-ui-mono'>{t(`${data.key}${i}`)}</p>);
    }
    return (
        <div>
            <p className='font-ui-mono color-rebeccapurple mb-0'>
                <span className='fw-bold'>{t('TOUR_MENU')}</span> - {t('TOUR_MENU_DES')}
            </p>
            {list}
        </div>
    );
};
export const parseData = (type) => {
    let service = {};
    let tour = {};
    let menu = {};
    let note = {};
    switch (type) {
        case ENUM_TOUR.HUE:
            service = {
                key: 'TOUR_HUE_SERVICE_',
                index: 5
            };
            tour = {
                time: 'TOUR_HUE_TIME_',
                key: 'TOUR_HUE_',
                index: 8
            };
            menu = {
                key: 'TOUR_HUE_MENU_',
                index: 10
            };
            break;
        case ENUM_TOUR.BANA_HILL:
            service = {
                key: 'TOUR_BANA_HILL_SERVICE_',
                index: 5
            };
            tour = {
                time: 'TOUR_BANA_HILL_TIME_',
                key: 'TOUR_BANA_HILL_',
                index: 8
            };
            break;
        case ENUM_TOUR.BANA_HILL2:
            service = {
                key: 'TOUR_BANA_HILL2_SERVICE_',
                index: 7
            };
            tour = {
                time: 'TOUR_BANA_HILL2_TIME_',
                key: 'TOUR_BANA_HILL2_',
                index: 8
            };
            break;
        case ENUM_TOUR.CULAOCHAM:
            service = {
                key: 'TOUR_CLC_SERVICE_',
                index: 5
            };
            tour = {
                time: 'TOUR_CLC_TIME_',
                key: 'TOUR_CLC_',
                index: 8
            };
            menu = {
                key: 'TOUR_CLC_MENU_',
                index: 12
            };
            break;
        case ENUM_TOUR.CHUALINHUNG:
            service = {
                key: 'TOUR_CLU_SERVICE_',
                index: 4
            };
            tour = {
                time: 'TOUR_CLU_TIME_',
                key: 'TOUR_CLU_',
                index: 7
            };
            menu = {
                key: 'TOUR_CLC_MENU_',
                index: 6
            };
            break;
        case ENUM_TOUR.BAYMAU_HOIAN:
            service = {
                key: 'TOUR_DBMHA_SERVICE_',
                index: 4
            };
            tour = {
                time: 'TOUR_DBMHA_TIME_',
                key: 'TOUR_DBMHA_',
                index: 6
            };
            note = {
                key: 'TOUR_DBMHA_NOTE_',
                index: 4
            };
            break;
        case ENUM_TOUR.BAYMAU:
            service = {
                key: 'TOUR_DBM_SERVICE_',
                index: 4
            };
            tour = {
                time: 'TOUR_DBM_TIME_',
                key: 'TOUR_DBMH_',
                index: 5
            };
            note = {
                key: 'TOUR_DBM_NOTE_',
                index: 4
            };
            break;
        case ENUM_TOUR.NHS:
            service = {
                key: 'TOUR_NHS_SERVICE_',
                index: 5
            };
            tour = {
                time: 'TOUR_NHS_TIME_',
                key: 'TOUR_NHS_',
                index: 6
            };
            menu = {
                key: 'TOUR_NHS_MENU_',
                index: 6
            };
            break;
        default:
            break;
    }
    return { note, service, menu, tour };
};
export const renderLeftPage = (data) => {
    return (
        <div className='w-30'>
            {renderImage(data.Type)}
            <div>
                <p className='font-ui-mono color-rebeccapurple mb-0 fw-bold pt-3'>{t('LABEL_TOUR_PROGRAM')}</p>
                {renderLeftMenu(data.service)}
            </div>
            <div className='d-flex justify-content-center py-2 align-items-center'>
                <div className='mb-0 py-2 text-white text-center bg-danger fw-bold align-items-center d-flex justify-content-center w-100'>
                    <Button className='mb-0 fs-md px-3 bg-danger border-0 w-100 text-center justify-content-center'>
                        {t('LABEL_TOUR_BOOK_NOW')}
                    </Button>
                </div>
            </div>
        </div>
    );
};
