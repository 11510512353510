import { InfoHotel } from 'app/constants';
import { commonService, generalService } from 'app/services';
import validatorChain from 'app/utils/validator-chain';
import { HttpStatusCode } from 'axios';
import { t } from 'i18next';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Map from './common/map';

export default function Contact() {
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Content, setContent] = useState('');
    const [error, setError] = useState({});
    /**
     * Validate data
     * @returns Boolean
     */
    const validateData = () => {
        const errors = {
            Name: validatorChain(Name.trim()).isRequire('M005').getError(),
            Email: validatorChain(Email.trim()).isRequire('M005').getError(),
            Content: validatorChain(Content.trim()).isRequire('M005').getError()
        };
        setError(errors);
        return Object.values(errors).reduce((r, c) => (c === null ? r : false), true);
    };
    /**
     * Add contact
     */
    const addContact = async () => {
        if (validateData()) {
            const res = await generalService.createContact({
                Name: Name.trim(),
                Email: Email.trim(),
                Content: Content.trim()
            });
            if (res.status === HttpStatusCode.Ok) {
                setName('');
                setError({});
                setContent('');
                setEmail('');
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M003')
                });
            } else {
                commonService.toast.show({
                    severity: 'error',
                    summary: t('ERROR_MESSAGE'),
                    detail: t('M004')
                });
            }
        }
    };
    return (
        <div className='auto-container py-5'>
            <div className='row contact-with'>
                <div className='col-md-6 col-sm-12'>
                    <h5 className='subtitle'>{t('LABEL_CONTACT_US')}</h5>
                    <h2 className='title'>{t('LABEL_CONTACT_WITH_US')}</h2>
                    <h2 className='text-right fw-bold pe-5 me-5 pt-2'>{t('LABEL_HOTEL')}</h2>
                    <div className='row w-100'>
                        <div className='icon-box py-4 d-flex border-bottom w-100'>
                            <div className='icons'>
                                <i className='pi pi-phone'></i>
                            </div>
                            <div className='pt-1'>
                                <h4 className='title-sub'>{t('LABEL_CALL_YOU')}</h4>
                                <p className='description-sub'> {InfoHotel.phone}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row w-100'>
                        <div className='icon-box py-4 d-flex border-bottom w-100'>
                            <div className='icons'>
                                <i className='pi pi-map-marker'></i>
                            </div>
                            <div className='pt-1'>
                                <h4 className='title-sub'>{t('LABEL_OUR_LOCATION_1')}</h4>
                                <p className='description-sub'>{InfoHotel.address1}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row w-100'>
                        <div className='icon-box py-4 d-flex w-100'>
                            <div className='icons'>
                                <i className='pi pi-map-marker'></i>
                            </div>
                            <div className='pt-1'>
                                <h4 className='title-sub'>{t('LABEL_OUR_LOCATION_2')}</h4>
                                <p className='description-sub'>{InfoHotel.address2} </p>
                            </div>
                        </div>
                    </div>
                    <div className='row w-100'>
                        <div className='icon-box py-4 d-flex w-100'>
                            <div className='icons'>
                                <i className='pi pi-inbox'></i>
                            </div>
                            <div className='pt-1'>
                                <h4 className='title-sub'>{t('LABEL_SEND_EMAIL')}</h4>
                                <p className='description-sub'>{InfoHotel.email} </p>
                            </div>
                        </div>
                    </div>
                    <div className='row w-100'>
                        <div className='icon-box pb-4 d-flex w-100'>
                            <div className='pt-1 d-flex'>
                                <h4 className='description-sub pt-2 px-3'>{t('LABEL_CONTACT_WITH_US')}</h4>
                                <div className='row'>
                                    <Button variant='social m-1 center-about'>
                                        <span className='icon agoda_b' />
                                    </Button>
                                    <Button variant='social m-1'>
                                        <span className='pi pi-instagram social-about' />
                                    </Button>
                                    <Button variant='social m-1'>
                                        <span className='pi pi-facebook social-about' />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <div className='comments-area border bg-contact p-5'>
                        <h3 className='text-white text-center'> {t('LABEL_GET_IN_TOUCH')} </h3>
                        <div className='row py-3'>
                            <div className='w-100 form-group py-3'>
                                <InputText
                                    id='name'
                                    value={Name}
                                    className='px-2 fs-md w-100'
                                    placeholder='Your Name'
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className='d-flex flex-column col-12 px-0'>
                                <label className='break-work text-danger'>{t(error['Name'])}</label>
                            </div>
                            <div className='w-100 form-group py-3'>
                                <InputText
                                    id='Email'
                                    value={Email}
                                    className='px-2 fs-md w-100'
                                    placeholder='Your Email'
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='d-flex flex-column col-12 px-0'>
                                <label className='break-work text-danger'>{t(error['Email'])}</label>
                            </div>
                        </div>
                        <div className='row pb-3'>
                            <div className='w-100 form-group'>
                                <InputTextarea
                                    id='Content'
                                    value={Content}
                                    className='px-2 fs-md w-100'
                                    placeholder='Message'
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-column col-12 px-0'>
                            <label className='break-work text-danger'>{t(error['Content'])}</label>
                        </div>
                        <div className='row py-3 px-2'>
                            <Button className='btn-action' variant='default' onClick={addContact}>
                                {t('LABEL_SEND_MESSAGE')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pb-3 pt-5'>
                <Map />
            </div>
        </div>
    );
}
