import { ENUM_TOUR } from 'app/constants';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { parseData, renderContent, renderLeftPage, renderMenu } from './common';
export default function NguHanhSonHoiAnTour() {
    const [data, setData] = useState([]);
    useEffect(() => {
        const res = parseData(ENUM_TOUR.NHS);
        setData(res);
    }, []);
    return (
        <div className='container'>
            <div className='d-flex justify-content-center py-5 row'>
                <div className='col-12'>
                    <div className='my-3'>
                        <div className='row pt-3'>
                            {renderLeftPage({ Type: ENUM_TOUR.NHS, service: data.service })}
                            <div className='w-70'>
                                <div className='font-ui-mono text-end row mb-0'>
                                    <p className='color-rebeccapurple fw-bold fs-34 w-50 mb-0'>{t('TOUR_NHS')}</p>
                                </div>
                                <p className='font-ui-mono color-chocolate fw-bold fs-24 text-right'>{t('LABEL_TOUR_GROUP')}</p>
                                <p className='mb-0 pb-3 font-ui-mono fs-md fw-bold'>{t('TOUR_NHS_DES')}</p>
                                {renderContent(data.tour)}
                                <hr className='break-line-bd' />
                                <div className='row pb-3'>
                                    <div className='w-50 px-3 pb-3'>{renderMenu(data.menu)}</div>
                                    <div className='w-50'>
                                        <p className='font-ui-mono color-rebeccapurple mb-0 fw-bold pt-1'>{t('TOUR_NOT_INCLUDE')}</p>
                                        <ul className='fs-md font-ui-mono mg-bt-12'>
                                            <li>{t('LABEL_TOUR_MORE_EXPENSIVE')}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='py-2 bg-chocolate'>
                                    <p className='mb-0 px-3 text-white text-center'>{t('LABEL_TOUR_FOOTER')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
