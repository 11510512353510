import { ImgAboutUs } from 'assets/image/header';
import { t } from 'i18next';
import { Image } from 'primereact/image';
import { Button } from 'react-bootstrap';
import AboutGallery from './gallery';
export default function AboutUs() {
    return (
        <div className='about auto-container py-5'>
            <div className='row d-flex align-items-center justify-content-center mb-5'>
                <div className='col-md-5 col-sm-12'>
                    <Image src={ImgAboutUs} alt='' imageClassName='w-100' />
                </div>
                <div className='col-md-7 col-sm-12'>
                    <div className='content-about'>
                        <div className='fs-lg mb-2'>{t('LABEL_ABOUT_US_1')}</div>
                        <h2 className='about-title pb-4'>{t('LABEL_ABOUT_US_2')}</h2>
                        <div className='text-two'>
                            <p>{t('LABEL_ABOUT_US_3')}</p>
                            <p>{t('LABEL_ABOUT_US_4')}</p>
                            <p>{t('LABEL_ABOUT_US_5')}</p>
                            <p>{t('LABEL_ABOUT_US_6')}</p>
                            <p>{t('LABEL_ABOUT_US_7')}</p>
                            <p>{t('LABEL_ABOUT_US_8')}</p>
                            <p>{t('LABEL_ABOUT_US_9')}</p>
                            <p>{t('LABEL_ABOUT_US_10')}</p>
                            <p className='mb-1'>{t('LABEL_ABOUT_US_11')}</p>
                            <p className='fw-bold mb-0'>{t('LABEL_ABOUT_US_12')}</p>
                        </div>
                        <div className='row pt-2'>
                            <Button variant='social'>
                                <span className='pi pi-twitter' />
                            </Button>
                            <Button variant='social'>
                                <span className='pi pi-instagram' />
                            </Button>
                            <Button variant='social'>
                                <span className='pi pi-facebook' />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row d-flex w-100 px-5'>
                <div className='row section_title text-center pt-5'>
                    <h2 className='title_w'>{t('LABEL_FACILITIES')}</h2>
                    <p>{t('LABEL_FACILITIES_DES')}</p>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon restaurant'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>{t('LABEL_RESTAURANT')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon spa'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>{t('LABEL_SPA')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon bar'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>{t('LABEL_SOUVENIR')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon motorcycle'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>{t('LABEL_RENT_MOTORCYCLE')}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon gym'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>{t('LABEL_GYM')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon swimming'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>{t('LABEL_SWIMMING_POOL')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon laundry'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>{t('LABEL_LAUNDRY')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon wifi'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>{t('LABEL_FREE_WIFI')}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AboutGallery />
        </div>
    );
}
