import { Carousel } from 'primereact/carousel';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars, prettier/prettier
import { ROUTER } from 'app/constants';
import { responsiveOptions } from 'app/constants/mock-data';
import { commonService, roomService } from 'app/services';
import { useRedirect } from 'app/utils/browser';
import environments from 'environments';
import { t } from 'i18next';
import { generatePath } from 'react-router-dom';

export default function CordialHotel() {
    const [data, setData] = useState([]);
    const redirect = useRedirect();
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await roomService.search({ selectedCategory: [1] });
        setData(data);
        commonService.preloader.hide();
        return true;
    };
    useEffect(() => {
        fetchData();
    }, []);
    const productTemplate = (item) => {
        return (
            <div className='w-100 room-block-two py-3'>
                <div className='inner-box pb-4'>
                    <div className='image'>
                        <img src={`${environments.PUBLIC_IMAGE}/image/room/${item.ID}/${item.ImageMain}`} alt='' />
                    </div>
                    <div className='px-3'>
                        <h3 className='blog-page-title'>
                            <a
                                href='/#'
                                className='fp-24 text-ellipsis-1line'
                                onClick={(e) => {
                                    e.preventDefault();
                                    redirect(generatePath(ROUTER.ROOM_DETAIL), ROUTER.ID, { id: item.ID }, item.ID);
                                }}>
                                {item.Name}
                            </a>
                        </h3>
                        <div className='icon-list'>
                            <ul className='row px-1'>
                                <li className='d-flex align-items-center col-6 py-2'>
                                    <span className='icon bed mx-0'></span>
                                    <span className='px-2 fs-md text-ellipsis-1line'>{item.RoomAmenities.main.bed}</span>
                                </li>
                                <li className='d-flex align-items-center col-6 py-2'>
                                    <span className='icon blueprint mx-0'></span>
                                    <span className='px-2 fs-md text-ellipsis-1line'>
                                        {item.RoomAmenities.main.blueprint}
                                        <span className='px-1'>
                                            m<sup>2</sup>
                                        </span>
                                    </span>
                                </li>
                                <li className='d-flex align-items-center col-6 py-2'>
                                    <span className='icon direction mx-0'></span>
                                    <span className='px-2 fs-md text-ellipsis-1line'>{item.RoomAmenities.main.direction}</span>
                                </li>
                                <li className='d-flex align-items-center col-6 py-2'>
                                    <span className='icon people mx-0'></span>
                                    <span className='px-2 fs-md text-ellipsis-1line'>{item.RoomAmenities.main.people}</span>
                                </li>
                            </ul>
                        </div>
                        <div className='text-two text-ellipsis-2line mh-56'>{item.SpecialDescription}</div>
                        <div className='row mx-0'>
                            <Button className='btn-action-sm' variant='default'>
                                {t('BTN_BOOK_NOW')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='auto-container common-back-ground'>
            <div className='text-center sec-title pt-4'>
                <div className='sub-title'>{t('LABEL_ROOM_SUITE')}</div>
                <h2 className='sec-title'>{t('LABEL_HOTEL')}</h2>
            </div>
            <div className='card news-block'>
                <Carousel
                    value={data}
                    numVisible={3}
                    numScroll={1}
                    responsiveOptions={responsiveOptions}
                    itemTemplate={productTemplate}
                    circular
                    autoplayInterval={5000}
                />
            </div>
        </div>
    );
}
