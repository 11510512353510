import { isString, toString } from 'lodash';
import validator from 'validator';
class ValidatorChain {
    constructor(str) {
        this.str = toString(str);
        this.errors = [];
    }
}
/**
 * Handle all validate rule
 */
Object.keys(validator).forEach(function (fn) {
    ValidatorChain.prototype[fn] = function (...rest) {
        const errorCode = rest.pop();
        const result = validator[fn].apply(validator, [this.str, ...rest]);
        if (isString(result)) {
            this.str = result;
            return this;
        }
        if (!result) {
            this.errors.push(errorCode);
        }
        return this;
    };
});
/**
 * Validate value is empty or not
 * @param {String} errorCode error code
 */
ValidatorChain.prototype.isRequire = function (errorCode) {
    if (validator.isEmpty(this.str)) {
        this.errors.push(errorCode);
    }
    return this;
};
/**
 * Custom rule validate
 * @param {String} rule rule name
 * @param {String} errorCode error code
 */
ValidatorChain.prototype.custom = function (rule, errorCode) {
    if (!rule(this.str)) {
        this.errors.push(errorCode);
    }
    return this;
};
/**
 * Get error in constructor
 */
ValidatorChain.prototype.getError = function () {
    // check errors length
    if (this.errors.length === 0) {
        return null;
    }
    return this.errors[0];
};
/**
 * Check there are errors or not
 * @param {Object} errors errors
 */
export function hasError(errors) {
    if (errors === null) {
        return false;
    }
    // get error code in errors
    let data = Object.values(errors);
    // filter item has no error
    data = data.filter((item) => item !== null);
    return data.length !== 0;
}
export default function (str) {
    return new ValidatorChain(str);
}
