/* eslint-disable no-undef */
//#region  import
// page
import { ROUTER } from 'app/constants';
import { LoaderDataError, MasterLayout, Preloader } from 'app/views/core';
// core
import {
    AboutUs,
    BanaHillTour,
    BanaHillTour2,
    BlogDetail,
    Blogs,
    CabanonRestaurant,
    ChuaLinhUngTour,
    Contact,
    CuLaoCham,
    DuaBayMauHoiAnTour,
    DuaBayMauTour,
    Events,
    Galleries,
    Gym,
    Homepage,
    HueTour,
    NguHanhSonHoiAnTour,
    NotFound,
    Restaurant,
    RoomDetail,
    Rooms,
    Services,
    Spa,
    Swimming,
    Tours
} from 'app/views/pages';
// environment
import env from 'environments';
import { Messages } from 'primereact/messages';
import { Toast } from 'primereact/toast';
import { useMemo } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

//#end region

export default function App() {
    const router = useMemo(
        () =>
            createBrowserRouter(
                createRoutesFromElements(
                    <Route errorElement={LoaderDataError}>
                        <Route element={<MasterLayout />}>
                            <Route path={ROUTER.BASE_NAME} key={ROUTER.BASE_NAME} element={<Homepage />} />
                            <Route path={ROUTER.GYM} element={<Gym />} />
                            <Route path={ROUTER.SERVICE} element={<Services />} />
                            <Route path={ROUTER.BLOG} element={<Blogs />} />
                            <Route path={ROUTER.BLOG_DETAIL} element={<BlogDetail />} />
                            <Route path={ROUTER.ROOM} element={<Rooms />} />
                            <Route path={ROUTER.ROOM_DETAIL} element={<RoomDetail />} />
                            <Route path={ROUTER.GALLERY} element={<Galleries />} />
                            <Route path={ROUTER.CONTACT} element={<Contact />} />
                            <Route path={ROUTER.MEETING_EVENT} element={<Events />} />
                            <Route path={ROUTER.ABOUT_US} element={<AboutUs />} />
                            <Route path={ROUTER.TOUR} element={<Tours />} />
                            <Route path={ROUTER.TOUR_BANAHILL} element={<BanaHillTour />} />
                            <Route path={ROUTER.TOUR_BANAHILL2} element={<BanaHillTour2 />} />
                            <Route path={ROUTER.CULAOCHAM} element={<CuLaoCham />} />
                            <Route path={ROUTER.CHUALINHUNG} element={<ChuaLinhUngTour />} />
                            <Route path={ROUTER.TOUR_HUE} element={<HueTour />} />
                            <Route path={ROUTER.TOUR_DUA_BAY_MAU_HOI_AN} element={<DuaBayMauHoiAnTour />} />
                            <Route path={ROUTER.TOUR_DUA_BAY_MAU} element={<DuaBayMauTour />} />
                            <Route path={ROUTER.TOUR_NGU_HANH_SON} element={<NguHanhSonHoiAnTour />} />
                            <Route path={ROUTER.SPA} element={<Spa />} />
                            <Route path={ROUTER.SWIMMING} element={<Swimming />} />
                            <Route path={ROUTER.BAR} element={<CabanonRestaurant />} />
                            <Route path={ROUTER.RESTAURANT} element={<Restaurant />} />
                            <Route path='*' element={<NotFound />} />
                        </Route>
                    </Route>
                ),
                {
                    basename: env.BASE_NAME
                }
            ),
        []
    );
    return (
        <>
            <RouterProvider router={router} />
            <Toast ref={(el) => (global.toast = el)} position='bottom-right' baseZIndex={1060} />
            <Messages
                ref={(el) => (global.messages = el)}
                className='position-absolute w-100 z-index-5 msg-notify d-none'
                style={{ top: 0 }}
            />
            <Preloader />
        </>
    );
}
